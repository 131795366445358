import React, { useEffect, useRef } from "react";
import gsap from "gsap";

import logo from "../../assets/images/logo_dark.svg";
import redPlus from "../../assets/images/red_star.svg";
import purplePlus from "../../assets/images/purple_star.svg";
import "./navbar.css";

const Navbar = () => {
  const logoRef = useRef();
  const navLink = useRef();

  useEffect(() => {
    let tl = gsap.timeline({ defaults: { duration: 1 } });
    tl.to(logoRef.current, { opacity: 1, x: 0 }).to(
      navLink.current,
      { opacity: 1, x: 0 },
      "-=1"
    );
  }, []);

  return (
    <nav className="fixed w-full bg-black/50 px-8 md:px-20 z-[100]">
      <div className="w-full flex flex-wrap justify-between items-center h-30 py-8">
        <div className="my-auto">
          <a href="#" ref={logoRef} className="opacity-0 -translate-x-16">
            <img
              src={logo}
              className="mr-3 mt-2 h-6 md:h-8"
              alt="Code++ Logo"
            />
          </a>
        </div>
        <div
          ref={navLink}
          className="opacity-0 flex items-center justify-center md:pr-10 mt-1"
        >
          <a
            href="#AboutUs"
            className="hover:text-gray-300 text-para align-middle text-white ml-4 font-semibold cursor-pointer text-sm md:text-lg"
          >
            <img
              src={redPlus}
              className="inline mr-3 mb-1 h-4"
              alt="Red Plus"
            />
            About
          </a>
          <a
            href="#faqs"
            className="hover:text-gray-300 text-para align-middle text-white ml-4 md:ml-7 font-semibold cursor-pointer text-sm md:text-lg"
          >
            <img
              src={purplePlus}
              className="inline mr-3 mb-1 h-4"
              alt="Purple Plus"
            />
            FAQs
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
