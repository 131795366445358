import React from "react";
import "./HorizontalComponent.css";
import StripThree from "../../assets/images/horizontal.strip3.svg";

const Third = () => {
  return (
    <>
      <div className="image-container">
        <img src={StripThree} alt="decorative strip" className="strip-image" />
      </div>
      <div className="horizontal-box">
        <div className="horizontal-content-box">
          <div className="big-number">3</div>
          <div className="horizontal-content">
            <div className="horizontal-content-title">{`Third`}</div>
            <div className="horizontal-content-text">
              {`The third prize of Code++ is worth`}
              <b style={{ color: "#7F30FF" }}>{` Rs. 3000`}</b>
              {`. Winning a prize is a great way to show off your skills and talents, and it's also a great way to motivate yourself to keep on striving for greatness.`}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Third;
