import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useWindowSize from "../../lib/useWindowSize";
import GreenArrowSmall from "../../assets/images/green-arrow-small.svg";
import GreenArrowBig from "../../assets/images/green-arrow-big.svg";
import BigLogo from "../../assets/images/big-logo.svg";
import "./Code2Create.css";

const CodeToCreate = () => {
  const { width, height } = useWindowSize();
  const designWidth = width > 500 ? 1366 : 375;
  const designHeight = 841;

  useEffect(() => {
    const ratioWidth = width / designWidth;
    const ratioHeight =
      width > 500 ? height / designHeight : (width * (4 / 3)) / designHeight;

    // set css variables
    document.documentElement.style.setProperty(
      "--page-width-factor",
      ratioWidth
    );
    document.documentElement.style.setProperty(
      "--page-height-factor",
      ratioHeight
    );
  }, [width, height]);

  const [email, setEmail] = useState("");
  const [registered, setRegistered] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("tk")) {
      setRegistered(true);
    }
    return () => {
      setRegistered(false);
    };
  }, []);
  const addUserToList = () => {
    setDisable(true);
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email.length === 0) {
      toast.error("Please enter an email");
      return;
    }

    if (!re.test(email)) {
      toast.error("The email provided is incorrect.");
      return;
    }
    axios({
      method: "post",
      url: `https://code-plus-plus-2022-backend.vercel.app/rsvp`,
      data: qs.stringify({
        email,
        captcha: window.sessionStorage.getItem("token")
      }),
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8"
      }
    }).then(res => {
      const {
        data: { success }
      } = res;
      if (success) {
        toast.success(res.data.msg);
        setEmail("");
        localStorage.setItem("tk", true);
        setRegistered(true);
        setDisable(false);
      } else {
        toast(res.data.msg);
        setDisable(false);
      }
      // const {success} = res.success;
    });
  };
  return (
    <div className="code-2-create">
      <div className="main-box">
        <div className="arrow-small-container">
          <img
            src={GreenArrowSmall}
            alt="decorative arrow"
            className="green-arrow-small"
          />
        </div>
        <div className="content-box">
          <div className="content-box-inner">
            <div className="content-box-title">Code2Create</div>
            <div className="content-box-text">
              {`Code 2 Create is ACM-VIT’s `}
              <text
                style={{ color: "#48BA86" }}
              >{`upcoming flagship hackathon`}</text>
              {`, where participants get the chance to innovate groundbreaking solutions while collaborating with their fellow techies, in just `}
              <text style={{ color: "#48BA86" }}>{`36 hours`}</text>
              {`. It is the ultimate test for passionate developers looking for a stage to showcase their technical mastery.`}
            </div>
          </div>
          <div className="content-box-input">
            <input
              type="text"
              placeholder="Enter your email"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
            <div className="content-box-input-button">
              <button
                type="button"
                onClick={e => {
                  addUserToList();
                }}
              >{`RSVP`}</button>
            </div>
          </div>
        </div>
        <div className="big-logo">
          <img src={BigLogo} alt="Code2Create logo" />
        </div>
        <div className="arrow-big-container">
          <img
            src={GreenArrowBig}
            alt="decorative arrow"
            className="green-arrow-big"
          />
        </div>
      </div>
    </div>
  );
};

export default CodeToCreate;
