import React from "react";
import { Strip, StripMobile } from "../../assets/images/strip";

const StripSeperator = () => {
  return (
    <div className="-mt-40 object-cover">
      <Strip />
      <StripMobile />
    </div>
  );
};

export default StripSeperator;
