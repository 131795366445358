export default [
  {
    id: "01",
    question: "Where will Code Plus Plus be held?",
    answer: "Code Plus Plus will be held on Hackerrank.",
  },
  {
    id: "02",
    question: "When will the contest be held?",
    answer:
      "The contest will be for 4 hours from 2:00 pm to 6:00 pm IST, on 3rd July (Sunday).",
  },
  {
    id: "03",
    question: "What programming languages can be used?",
    answer:
      "There are no restrictions on the programming languages that can be used to solve the problems.",
  },
  {
    id: "04",
    question: "How can I register for the event?",
    answer:
      "After clicking on ‘Register’ button, fill in the necessary details. After that you will be directed to Hacckerank. You have to register for the event there as well.",
  },
  {
    id: "05",
    question: "Who all can participate in Code Plus Plus?",
    answer:
      "Participation is open for all. There is something for everyone - from absolute beginners to seasoned professionals.",
  },
  {
    id: "06",
    question: "What if I have more questions?",
    answer:
      "If your query is still unresolved, you can head over to the <u><b><a target='_blankk' rel='noopener noreferrer' href='https://discord.gg/ZMab8rPYVY'>discord server</a></b></u> and ask your question there!",
  },
];
