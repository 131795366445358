import "./App.css";
import React from "react";
import Landing from "./components/Landing";
import Sponsor from "./components/Sponsor";
import About from "./components/About";
import Horizontal from "./components/Horizontal";
import Faq from "./components/Faq";
import CodeToCreate from "./components/CodeToCreate";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import StripSeperator from "./components/StripSeperator";
import FloatRegister from "./components/FloatRegister";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="app relative">
      <Navbar />
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <FloatRegister />
      <Landing />
      <StripSeperator />
      {/* <Sponsor /> */}
      <About />
      <Horizontal />
      <CodeToCreate />
      <Faq />
      <Footer />
    </div>
  );
}

export default App;
