import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { useVisibleUpdateContext } from "../../contexts/VisibleContext";
import { useElementOnScreen } from "../../hooks";

import Register from "../../assets/images/Register";
import blink from "../../assets/images/blinkblack.gif";
import "./landing.css";

const Landing = () => {
  const [landingWindow, isVisible] = useElementOnScreen({
    threshold: 0.5,
  });
  const registerBtn = useRef();
  const heroTitle = useRef();
  const blinkImg = useRef();
  const setVisible = useVisibleUpdateContext();

  useEffect(() => {
    setVisible(!isVisible);
  }, [isVisible]);

  useEffect(() => {
    let tl = gsap.timeline({ defaults: { duration: 1 } });
    tl.to(heroTitle.current, { opacity: 1, y: "-2rem" }).to(
      registerBtn.current,
      { opacity: 1, y: 0 },
      "-=2"
    );
    let tlBlink = gsap.timeline({
      defaults: { ease: "expo.out", duration: 1 },
    });
    tlBlink.to(blinkImg.current, { x: "6rem" });
  }, []);

  return (
    <div ref={landingWindow} className="h-screen">
      <div className="flex flex-nowrap items-center justify-center h-full relative">
        <span
          ref={heroTitle}
          className="opacity-0 translate-y-8 text-5xl sm:text-6xl md:text-8xl 2xl:text-9xl my-40 text-title font-semibold tracking-wider"
        >
          Code Plus Plus
        </span>
        <a
          id="registerBtn"
          ref={registerBtn}
          href="https://code-plus-plus-2022-backend.vercel.app/google"
          className="absolute opacity-0 translate-y-8 bottom-24 sm:bottom-40 z-50 transition-all duration-1000 ease-out"
        >
          <Register alt="register button" />
        </a>
        <span
          ref={blinkImg}
          className="transition-all duration-1000 ease-out absolute -left-24 bottom-52 md:bottom-40 z-10"
        >
          <img className="h-40 md:h-52 lg:h-64" src={blink} alt="Blink GIF" />
        </span>
      </div>
    </div>
  );
};

export default Landing;
