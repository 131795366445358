import React, { createContext, useContext, useState } from "react";
import PropTypes from 'prop-types';

const VisibleContext = createContext();
const VisibleUpdateContext = createContext();

export function useVisibleContext() {
  return useContext(VisibleContext);
}

export function useVisibleUpdateContext() {
  return useContext(VisibleUpdateContext);
}

export function VisibleProvider({ children }) {
  const [visible, setVisible] = useState(false);

  function toggleVisible() {
    setVisible((prev) => !prev);
  }

  return (
    <VisibleContext.Provider value={visible}>
      <VisibleUpdateContext.Provider value={toggleVisible}>
        {children}
      </VisibleUpdateContext.Provider>
    </VisibleContext.Provider>
  );
}

VisibleProvider.propTypes = {
    children: PropTypes.any,
}
