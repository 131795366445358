import React from "react";

import ring from "../../assets/images/ring.png";

const About = () => {
  return (
    <div
      id="AboutUs"
      className="relative h-fit md:h-[150vh] w-screen flex items-center justify-center mt-20 md:mt-12"
    >
      <img
        src={ring}
        className="hidden left-[50%] -translate-x-[50%] sm:block absolute h-full md:h-[90%] lg:h-full object-contain"
      />
      <div className="bg-black p-6 w-[80%] md:w-[55%] h-fit z-20">
        <div className="bg-white h-full text-black">
          <div className="flex flex-col justify-center items-center px-2">
            <h1 className="text-title text-5xl font-bold my-14 text-center tracking-wide">
              Code Plus Plus
            </h1>
            <p className="z-50 text-black text-center text-base md:text-lg lg:text-2xl 2xl:text-3xl px-2 md:px-12 my-auto mb-8">
              {`Code Plus Plus is ACM-VIT’s annual competitive coding competition that challenges an individual's mathematical prowess and logical thinking skills.`}
              <br /> <br />
              <b>{`Date: 3rd July 2022, Sunday`}</b>
              <br />
              <b>{`Time: 2:00 p.m. to 6:00 p.m. `}</b>
              <br />
              <b>{`Venue: HackerRank`}</b>
              <br />
              <br />
              {`The event is a`}
              <b>{` four-hour `}</b>
              {`competition in which the participants are awarded points based on the number of test cases they can pass successfully.`}
              <br />
              {`This is a valuable opportunity to recognize and develop one's true potential in competitive coding`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
