import React, { useEffect } from "react";
import HorizontalScroll from "./HorizontalScroll";
import FirstHorizontal from "./FirstHorizontal";
import SecondHorizontal from "./SecondHorizontal";
import ThirdHorizontal from "./ThirdHorizontal";
import useWindowSize from "../../lib/useWindowSize";
import "./Horizontal.css";

const Horizontal = () => {
  const { width, height } = useWindowSize();
  const designWidth = 1366;
  const designHeight = 841;

  useEffect(() => {
    const ratioWidth = width / designWidth;
    const ratioHeight = height / designHeight;

    // set css variables
    document.documentElement.style.setProperty(
      "--page-width-factor",
      ratioWidth
    );
    document.documentElement.style.setProperty(
      "--page-height-factor",
      ratioHeight
    );
  }, [width, height]);
  return (
    <div>
      <div className="big-screen">
        <HorizontalScroll
          elements={[
            <FirstHorizontal key={1} />,
            <SecondHorizontal key={2} />,
            <ThirdHorizontal key={3} />,
          ]}
        />
      </div>
    </div>
  );
};

export default Horizontal;
