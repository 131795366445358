import React from "react";
import "./HorizontalComponent.css";
import StripOne from "../../assets/images/horizontal.strip1.svg";

const FirstHorizontal = () => {
  return (
    <>
      <div className="image-container">
        <img src={StripOne} alt="decorative strip" className="strip-image" />
      </div>
      <div className="horizontal-box">
        <div className="horizontal-content-box">
          <div className="big-number">1</div>
          <div className="horizontal-content">
            <div className="horizontal-content-title">{`First`}</div>
            <div className="horizontal-content-text">
              {`The winner of Code++ bags a cash-prize worth`}
              <b style={{ color: "#7F30FF" }}>{` Rs. 7000`}</b>
              {`. It's always great to see hard work pay off, and that's exactly what happens when you win a competitive event's first prize. `}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstHorizontal;
