import React from "react";

import companies from "./sponsorsData";
import { Swiper, SwiperSlide } from "swiper/react";

import { A11y, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/bundle";

const Carousel = () => {
  return (
    <div className="mt-16 flex items-center justify-center">
      <Swiper
        modules={[A11y, Autoplay]}
        loop={true}
        centeredSlides= {true}
        centeredSlidesBounds={true}
        speed={10000}
        autoplay={{
          delay: -10,
          disableOnInteraction: false,
        }}
        breakpoints={{
          499: {
            slidesPerView: 3,
            spaceBetweenSlides: 2,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 4,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
        }}
      >
        {companies.map((company, index) => (
          <SwiperSlide key={index}>
            <div className="slide-content bg-black/50 rounded-lg flex items-center h-20 w-36 px-5">
              <div className="logos">
                <a href={company?.url} target="_blank" rel="noreferrer">
                  <img src={company.icon} />
                </a>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
