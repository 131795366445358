import React from "react";

import acmLogo from "../../assets/images/ACM-Logo.svg";
import Fb from "../../assets/images/Fb.svg";
import Twitter from "../../assets/images/Footer-Twitter.svg";
import Instagram from "../../assets/images/Footer-IG.svg";
import LinkedIn from "../../assets/images/Footer-Linkedin.svg";
import Medium from "../../assets/images/Medium.svg";
import vitLogo from "../../assets/images/VIT-Logo.svg";
import Discord from "../../assets/images/discord.svg";
import Blink from "../../assets/images/blink.gif";

const footerSection = () => (
  <>
    {/* Footer - Desktop View */}
    <footer className="overflow-hidden invisible md:visible lg:visible xl:visible xf:visible xxl:visible xxxl:visible md:flex lg:flex xl:flex xf:flex xxl:flex xxxl:flex justify-between h-24 px-20 bg-[#7F30FF] items-center">
      <div>
        <a target="_blank" rel="noopener noreferrer" href="https://acmvit.in/">
          <img src={acmLogo} alt="ACM-Logo" />
        </a>
      </div>
      <img src={Blink} className="ml-auto scale-50 mb-1.5" />
      <div className="flex mr-auto">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/acmvitvellore/"
          className="mr-2"
        >
          <img src={Fb} alt="FB" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/ACM_VIT"
          className="mr-2"
        >
          <img src={Twitter} alt="Twitter" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://instagram.com/acmvit"
          className="mr-2"
        >
          <img src={Instagram} alt="Instagram" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://medium.com/acmvit"
          className="mr-2"
        >
          <img src={Medium} alt="Medium" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/acm-vit-chapter/mycompany/"
          className="mr-2"
        >
          <img src={LinkedIn} alt="LinkedIn" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://discord.gg/ZMab8rPYVY"
          className="mr-2"
        >
          <img src={Discord} alt="Discord" />
        </a>
      </div>
      <div>
        <a target="_blank" rel="noopener noreferrer" href="https://vit.ac.in/">
          <img src={vitLogo} alt="VIT-Logo" />
        </a>
      </div>
    </footer>

    {/* Footer - mobile view */}
    <footer className="overflow-hidden sm:flex-col justify-between h-32 px-12 bg-[#7F30FF] items-center md:hidden">
      <div className="flex justify-center py-4">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/acmvitvellore/"
          className="mr-2"
        >
          <div className="blink_me">
            <img src={Fb} alt="FB" />
          </div>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/ACM_VIT"
          className="mr-2"
        >
          <img src={Twitter} alt="Twitter" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://instagram.com/acmvit"
          className="mr-2"
        >
          <img src={Instagram} alt="Instagram" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://medium.com/acmvit"
          className="mr-2"
        >
          <img src={Medium} alt="Medium" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/acm-vit-chapter/mycompany/"
          className="mr-2"
        >
          <img src={LinkedIn} alt="LinkedIn" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://discord.gg/ZMab8rPYVY"
          className="mr-2"
        >
          <img src={Discord} alt="Discord" />
        </a>
      </div>
      <div className="flex justify-between">
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://acmvit.in/"
          >
            <img src={acmLogo} alt="ACM-Logo" />
          </a>
        </div>
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://vit.ac.in/"
          >
            <img src={vitLogo} alt="VIT-Logo" />
          </a>
        </div>
      </div>
    </footer>
  </>
);

export default footerSection;
