import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { BrowserRouter } from "react-router-dom";
import { VisibleProvider } from "./contexts/VisibleContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <VisibleProvider>
      <GoogleReCaptchaProvider reCaptchaKey="6Lfwfn8gAAAAAPHPEsjbD4pFhwTnbYPhaYiJRkfR">
        <GoogleReCaptcha
          onVerify={(token) => {
            window.sessionStorage.setItem("token", token);
          }}
        />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    </VisibleProvider>
  </React.StrictMode>
);
