import React, { useState } from "react";
import PropTypes from "prop-types";
import "./HorizontalScroll.css";

const HorizontalScroll = ({ elements }) => {
  const [numElements] = useState(elements.length);
  return (
    <div className="outer-outer-wrapper">
      <div className="outer-wrapper">
        <div
          className="inner-wrapper"
          style={{
            width: `${numElements * 100}vw`,
          }}
        >
          {elements.map((element, index) => (
            <div key={index} className="slide">
              {element}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

HorizontalScroll.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default HorizontalScroll;
