import React, { useState, useRef, useEffect } from "react";

import "./Accordion.css";

import { FiPlus } from "react-icons/fi";

const Accordion = (props) => {
  const [active, setActive] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : "0px";
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };

  return (
    <button
      type="button"
      className={
        active
          ? props.id % 2 == 0
            ? `question-section active-even ${active} divider`
            : `question-section active-odd ${active} divider`
          : props.id % 2 == 0
          ? `question-section even ${active} divider`
          : `question-section odd ${active} divider`
      }
      onClick={toggleAccordion}
    >
      <div>
        <div className="question-title">
          <div className="number">{props.id}</div>
          <div className="ques_title">{props.title}</div>
          <FiPlus className={active ? `answer-icon` : `question-icon`} />
        </div>
        <div
          ref={contentRef}
          className={`answer`}
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </button>
  );
};

export default Accordion;
