import React, { useRef, useEffect } from "react";
import { useVisibleContext } from "../../contexts/VisibleContext";

import blinkPurple from "../../assets/images/blink_reg_purple.gif";
import blinkPink from "../../assets/images/blink_reg_pink.gif";

const FloatRegister = () => {
  const visible = useVisibleContext();
  const imgRef = useRef();

  const hoverHandlerIn = () => {
    imgRef.current.setAttribute("src", blinkPink);
  };

  const hoverHandlerOut = () => {
    imgRef.current.setAttribute("src", blinkPurple);
  };

  useEffect(() => {
    imgRef.current.addEventListener("mouseover", hoverHandlerIn);
    imgRef.current.addEventListener("mouseout", hoverHandlerOut);

    return () => {
      imgRef.current.removeEventListener("mouseover", hoverHandlerIn);
      imgRef.current.removeEventListener("mouseout", hoverHandlerOut);
    };
  }, []);

  return (
    <a href="https://code-plus-plus-2022-backend.vercel.app/google">
      <div
        className={`fixed bottom-0 sm:bottom-6 left-2 sm:left-10 z-50 duration-200 ease-out ${
          visible ? "scale-125" : "scale-0"
        }`}
      >
        <div className="h-24 md:h-36 w-24 md:w-36 hover:scale-110 duration-100 ease-out">
          <img ref={imgRef} src={blinkPurple} alt="Purple Blink" />
        </div>
      </div>
    </a>
  );
};

export default FloatRegister;
