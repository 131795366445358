import React from "react";

export const Strip = () => {
  return (
    <svg
      className="hidden sm:block"
      viewBox="0 0 1520 276"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M617.17 38.9188V38.9188C605.928 38.9188 596.621 47.6536 595.909 58.8727L593.533 96.2897C591.493 128.417 564.841 153.431 532.649 153.431V153.431C500.457 153.431 473.805 128.417 471.765 96.2898L469.505 60.6867C468.728 48.4477 458.574 38.9188 446.311 38.9188V38.9188C434.047 38.9188 423.894 48.4477 423.117 60.6867L420.864 96.1746C418.82 128.367 392.114 153.431 359.857 153.431H358.27C326.013 153.431 299.308 128.367 297.264 96.1747L295.011 60.6867C294.234 48.4477 284.08 38.9188 271.817 38.9188V38.9188C259.553 38.9188 249.4 48.4477 248.623 60.6867L246.37 96.1746C244.326 128.367 217.62 153.431 185.363 153.431H183.776C151.519 153.431 124.814 128.367 122.77 96.1746L120.538 61.0311C119.749 48.5984 109.435 38.9188 96.9775 38.9188V38.9188C84.8061 38.9188 74.6296 48.1721 73.4756 60.2887L70.0579 96.1747C66.9661 128.638 39.7005 153.431 7.08998 153.431H0.987305"
        stroke="black"
        strokeWidth="14.5412"
      />
      <path
        d="M617.17 24.3776V24.3776C598.096 24.3776 582.634 39.8396 582.634 58.9129V88.9041C582.634 116.51 560.255 138.889 532.649 138.889V138.889C505.043 138.889 482.664 116.51 482.664 88.9041V60.7306C482.664 40.6534 466.388 24.3776 446.311 24.3776V24.3776C426.234 24.3776 409.958 40.6534 409.958 60.7306V87.9953C409.958 116.103 387.172 138.889 359.064 138.889V138.889C330.956 138.889 308.17 116.103 308.17 87.9952V60.7306C308.17 40.6534 291.894 24.3776 271.817 24.3776V24.3776C251.74 24.3776 235.464 40.6534 235.464 60.7306V87.9953C235.464 116.103 212.678 138.889 184.57 138.889V138.889C156.462 138.889 133.676 116.103 133.676 87.9953V60.7306C133.676 40.6534 117.4 24.3776 97.3226 24.3776V24.3776C77.2454 24.3776 60.9697 40.6534 60.9697 60.7306V82.5423C60.9697 113.662 35.7422 138.889 4.6226 138.889V138.889"
        stroke="#4A90E2"
        strokeWidth="14.5412"
      />
      <path
        d="M617.17 9.83643V9.83643C590.065 9.83643 568.093 31.8087 568.093 58.9129V88.9041C568.093 108.479 552.224 124.348 532.649 124.348V124.348C513.074 124.348 497.205 108.479 497.205 88.9041V60.7306C497.205 32.6225 474.419 9.83643 446.311 9.83643V9.83643C418.203 9.83643 395.417 32.6225 395.417 60.7306V87.9952C395.417 108.072 379.141 124.348 359.064 124.348V124.348C338.987 124.348 322.711 108.072 322.711 87.9952V60.7306C322.711 32.6225 299.925 9.83643 271.817 9.83643V9.83643C243.709 9.83643 220.923 32.6225 220.923 60.7306V87.9952C220.923 108.072 204.647 124.348 184.57 124.348V124.348C164.492 124.348 148.217 108.074 148.217 87.9972C148.217 79.5926 148.217 70.2929 148.217 60.7315C148.217 32.6235 125.431 9.83643 97.3226 9.83643V9.83643C69.2145 9.83643 46.4285 32.6225 46.4285 60.7306V82.5423C46.4285 105.631 27.7114 124.348 4.6226 124.348V124.348"
        stroke="#FF007A"
        strokeWidth="14.5412"
      />
      <path
        d="M618.987 8.0188V8.0188C587.306 8.0188 561.077 32.6352 559.07 64.2526L556.694 101.67C555.949 113.399 546.219 122.531 534.467 122.531V122.531C522.714 122.531 512.984 113.399 512.239 101.67L509.928 65.2747C507.885 33.0827 481.179 8.0188 448.922 8.0188H447.335C415.078 8.0188 388.372 33.0826 386.328 65.2747L384.075 100.763C383.298 113.002 373.145 122.531 360.881 122.531V122.531C348.618 122.531 338.465 113.002 337.688 100.763L335.434 65.2747C333.39 33.0827 306.685 8.0188 274.428 8.0188H272.841C240.584 8.0188 213.878 33.0826 211.834 65.2747L209.581 100.763C208.804 113.002 198.651 122.531 186.387 122.531V122.531C174.124 122.531 163.971 113.002 163.193 100.763L160.94 65.2747C158.896 33.0826 132.191 8.0188 99.9337 8.0188H98.3467C66.0899 8.0188 39.3842 33.0827 37.3402 65.2747L35.4326 95.3207C34.4612 110.619 21.7698 122.531 6.44025 122.531V122.531"
        stroke="#7F30FF"
        strokeWidth="14.5412"
      />
      <path
        d="M616.805 38.9188V38.9188C628.047 38.9188 637.354 47.6536 638.066 58.8727L640.442 96.2897C642.481 128.417 669.134 153.431 701.326 153.431V153.431C733.518 153.431 760.17 128.417 762.209 96.2898L764.47 60.6867C765.247 48.4477 775.4 38.9188 787.664 38.9188V38.9188C799.927 38.9188 810.081 48.4477 810.858 60.6867L813.111 96.1746C815.155 128.367 841.86 153.431 874.117 153.431H875.704C907.961 153.431 934.667 128.367 936.711 96.1747L938.964 60.6867C939.741 48.4477 949.894 38.9188 962.158 38.9188V38.9188C974.422 38.9188 984.575 48.4477 985.352 60.6867L987.605 96.1746C989.649 128.367 1016.35 153.431 1048.61 153.431H1050.2C1082.46 153.431 1109.16 128.367 1111.2 96.1746L1113.44 61.0311C1114.23 48.5984 1124.54 38.9188 1137 38.9188V38.9188C1149.17 38.9188 1159.35 48.1721 1160.5 60.2887L1163.92 96.1747C1167.01 128.638 1194.27 153.431 1226.88 153.431H1232.99"
        stroke="black"
        strokeWidth="14.5412"
      />
      <path
        d="M616.805 24.3776V24.3776C635.878 24.3776 651.34 39.8396 651.34 58.9129V88.9041C651.34 116.51 673.719 138.889 701.326 138.889V138.889C728.932 138.889 751.311 116.51 751.311 88.9041V60.7306C751.311 40.6534 767.587 24.3776 787.664 24.3776V24.3776C807.741 24.3776 824.017 40.6534 824.017 60.7306V87.9953C824.017 116.103 846.803 138.889 874.911 138.889V138.889C903.019 138.889 925.805 116.103 925.805 87.9952V60.7306C925.805 40.6534 942.081 24.3776 962.158 24.3776V24.3776C982.235 24.3776 998.511 40.6534 998.511 60.7306V87.9953C998.511 116.103 1021.3 138.889 1049.4 138.889V138.889C1077.51 138.889 1100.3 116.103 1100.3 87.9953V60.7306C1100.3 40.6534 1116.57 24.3776 1136.65 24.3776V24.3776C1156.73 24.3776 1173 40.6534 1173 60.7306V82.5423C1173 113.662 1198.23 138.889 1229.35 138.889V138.889"
        stroke="#4A90E2"
        strokeWidth="14.5412"
      />
      <path
        d="M616.805 9.83643V9.83643C643.909 9.83643 665.881 31.8087 665.881 58.9129V88.9041C665.881 108.479 681.75 124.348 701.326 124.348V124.348C720.901 124.348 736.77 108.479 736.77 88.9041V60.7306C736.77 32.6225 759.556 9.83643 787.664 9.83643V9.83643C815.772 9.83643 838.558 32.6225 838.558 60.7306V87.9952C838.558 108.072 854.834 124.348 874.911 124.348V124.348C894.988 124.348 911.264 108.072 911.264 87.9952V60.7306C911.264 32.6225 934.05 9.83643 962.158 9.83643V9.83643C990.266 9.83643 1013.05 32.6225 1013.05 60.7306V87.9952C1013.05 108.072 1029.33 124.348 1049.4 124.348V124.348C1069.48 124.348 1085.76 108.074 1085.76 87.9972C1085.76 79.5926 1085.76 70.2929 1085.76 60.7315C1085.76 32.6235 1108.54 9.83643 1136.65 9.83643V9.83643C1164.76 9.83643 1187.55 32.6225 1187.55 60.7306V82.5423C1187.55 105.631 1206.26 124.348 1229.35 124.348V124.348"
        stroke="#FF007A"
        strokeWidth="14.5412"
      />
      <path
        d="M614.987 8.0188V8.0188C646.668 8.0188 672.897 32.6352 674.905 64.2526L677.28 101.67C678.025 113.399 687.755 122.531 699.508 122.531V122.531C711.261 122.531 720.991 113.399 721.735 101.67L724.046 65.2747C726.09 33.0827 752.796 8.0188 785.053 8.0188H786.64C818.897 8.0188 845.602 33.0826 847.646 65.2747L849.899 100.763C850.676 113.002 860.83 122.531 873.093 122.531V122.531C885.357 122.531 895.51 113.002 896.287 100.763L898.54 65.2747C900.584 33.0827 927.29 8.0188 959.547 8.0188H961.134C993.391 8.0188 1020.1 33.0826 1022.14 65.2747L1024.39 100.763C1025.17 113.002 1035.32 122.531 1047.59 122.531V122.531C1059.85 122.531 1070 113.002 1070.78 100.763L1073.03 65.2747C1075.08 33.0826 1101.78 8.0188 1134.04 8.0188H1135.63C1167.88 8.0188 1194.59 33.0827 1196.63 65.2747L1198.54 95.3207C1199.51 110.619 1212.2 122.531 1227.53 122.531V122.531"
        stroke="#7F30FF"
        strokeWidth="14.5412"
      />
      <path
        d="M1519 268.431H1486.12C1453.86 268.431 1427.15 243.367 1425.11 211.175L1422.86 175.687C1422.08 163.448 1411.93 153.919 1399.66 153.919V153.919C1387.4 153.919 1377.25 163.448 1376.47 175.687L1374.21 211.29C1372.17 243.417 1345.52 268.431 1313.33 268.431V268.431C1281.13 268.431 1254.48 243.417 1252.44 211.29L1250.07 173.873C1249.35 162.654 1240.05 153.919 1228.8 153.919V153.919"
        stroke="black"
        strokeWidth="14.5412"
      />
      <path
        d="M1519 253.889H1493.27C1461.65 253.889 1436.02 228.255 1436.02 196.634V175.731C1436.02 155.653 1419.74 139.378 1399.66 139.378V139.378C1379.59 139.378 1363.31 155.653 1363.31 175.731V203.904C1363.31 231.51 1340.93 253.889 1313.33 253.889V253.889C1285.72 253.889 1263.34 231.51 1263.34 203.904V173.913C1263.34 154.84 1247.88 139.378 1228.8 139.378V139.378"
        stroke="#4A90E2"
        strokeWidth="14.5412"
      />
      <path
        d="M1519.5 239.348H1491.5V239.348C1468.89 239.348 1450.56 221.018 1450.56 198.406V175.731C1450.56 147.623 1427.77 124.836 1399.66 124.836V124.836C1371.56 124.836 1348.77 147.623 1348.77 175.731V203.904C1348.77 223.479 1332.9 239.348 1313.33 239.348V239.348C1293.75 239.348 1277.88 223.479 1277.88 203.904V173.913C1277.88 146.809 1255.91 124.836 1228.8 124.836V124.836"
        stroke="#FF007A"
        strokeWidth="14.5412"
      />
      <path
        d="M1517.5 240V240C1486.65 238.595 1461.8 214.188 1459.84 183.365L1459.7 181.067C1457.62 148.429 1430.15 123.019 1397.45 123.019V123.019C1365.19 123.019 1338.09 148.083 1336.05 180.275L1333.74 216.67C1332.99 228.399 1323.26 237.531 1311.51 237.531V237.531C1299.76 237.531 1290.02 228.399 1289.28 216.67L1286.9 179.253C1284.9 147.635 1258.67 123.019 1226.99 123.019V123.019"
        stroke="#7F30FF"
        strokeWidth="14.5412"
      />
    </svg>
  );
};

export const StripMobile = () => {
  return (
    <svg
      className="sm:hidden w-full"
      width="600"
      height="135"
      viewBox="0 0 1231 276"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.02357 38.6941V38.6941C13.2652 38.6941 22.5723 47.429 23.2846 58.648L25.6603 96.0651C27.7001 128.192 54.3521 153.206 86.5442 153.206V153.206C118.736 153.206 145.388 128.192 147.428 96.0651L149.689 60.4621C150.466 48.2231 160.619 38.6941 172.882 38.6941V38.6941C185.146 38.6941 195.299 48.223 196.076 60.462L198.329 95.95C200.373 128.142 227.079 153.206 259.336 153.206L260.923 153.206C293.18 153.206 319.886 128.142 321.929 95.95L324.183 60.4621C324.96 48.2231 335.113 38.6941 347.377 38.6941V38.6941C359.64 38.6941 369.793 48.2231 370.57 60.462L372.824 95.95C374.868 128.142 401.573 153.206 433.83 153.206L435.417 153.206C467.674 153.206 494.38 128.142 496.424 95.95L498.655 60.8064C499.444 48.3738 509.758 38.6941 522.216 38.6941V38.6941C534.387 38.6941 544.564 47.9475 545.718 60.0641L549.135 95.95C552.227 128.414 579.493 153.206 612.103 153.206L618.206 153.206"
        stroke="black"
        strokeWidth="14.5412"
      />
      <path
        d="M2.02355 24.1529V24.1529C21.0969 24.1529 36.5588 39.6149 36.5588 58.6882L36.5588 88.6794C36.5588 116.286 58.938 138.665 86.5441 138.665V138.665C114.15 138.665 136.529 116.285 136.529 88.6794L136.529 60.5059C136.529 40.4287 152.805 24.1529 172.882 24.1529V24.1529C192.96 24.1529 209.235 40.4287 209.235 60.5059L209.235 87.7706C209.235 115.879 232.021 138.665 260.129 138.665V138.665C288.237 138.665 311.024 115.879 311.024 87.7706L311.024 60.5059C311.024 40.4287 327.299 24.1529 347.376 24.1529V24.1529C367.454 24.1529 383.729 40.4287 383.729 60.5059L383.729 87.7706C383.729 115.879 406.515 138.665 434.624 138.665V138.665C462.732 138.665 485.518 115.879 485.518 87.7706L485.518 60.5059C485.518 40.4287 501.793 24.1529 521.871 24.153V24.153C541.948 24.153 558.224 40.4287 558.224 60.5059L558.224 82.3176C558.224 113.437 583.451 138.665 614.571 138.665V138.665"
        stroke="#4A90E2"
        strokeWidth="14.5412"
      />
      <path
        d="M2.02355 9.61176V9.61176C29.1277 9.61176 51.1 31.5841 51.1 58.6882L51.1 88.6794C51.1 108.255 66.9689 124.124 86.5441 124.124V124.124C106.119 124.124 121.988 108.255 121.988 88.6794L121.988 60.5059C121.988 32.3978 144.774 9.61177 172.882 9.61177V9.61177C200.99 9.61177 223.776 32.3979 223.776 60.5059L223.776 87.7706C223.776 107.848 240.052 124.124 260.129 124.124V124.124C280.207 124.124 296.482 107.848 296.482 87.7706L296.482 60.5059C296.482 32.3979 319.268 9.61177 347.376 9.61177V9.61177C375.485 9.61178 398.271 32.3979 398.271 60.5059L398.271 87.7706C398.271 107.848 414.546 124.124 434.624 124.124V124.124C454.701 124.124 470.976 107.85 470.976 87.7725C470.976 79.368 470.976 70.0682 470.976 60.5069C470.976 32.3988 493.763 9.61178 521.871 9.61178V9.61178C549.979 9.61178 572.765 32.3979 572.765 60.5059L572.765 82.3176C572.765 105.406 591.482 124.124 614.571 124.124V124.124"
        stroke="#FF007A"
        strokeWidth="14.5412"
      />
      <path
        d="M0.205918 7.7941V7.7941C31.887 7.7941 58.1159 32.4105 60.1234 64.0279L62.4991 101.445C63.2438 113.174 72.9739 122.306 84.7265 122.306V122.306C96.4792 122.306 106.209 113.174 106.954 101.445L109.265 65.05C111.309 32.858 138.014 7.79411 170.271 7.79411L171.858 7.79411C204.115 7.79411 230.821 32.8579 232.865 65.05L235.118 100.538C235.895 112.777 246.048 122.306 258.312 122.306V122.306C270.575 122.306 280.729 112.777 281.506 100.538L283.759 65.05C285.803 32.858 312.509 7.79412 344.765 7.79412L346.352 7.79412C378.609 7.79412 405.315 32.8579 407.359 65.05L409.612 100.538C410.389 112.777 420.542 122.306 432.806 122.306V122.306C445.07 122.306 455.223 112.777 456 100.538L458.253 65.05C460.297 32.8579 487.003 7.79412 519.26 7.79412L520.847 7.79412C553.103 7.79413 579.809 32.858 581.853 65.05L583.761 95.096C584.732 110.395 597.423 122.306 612.753 122.306V122.306"
        stroke="#7F30FF"
        strokeWidth="14.5412"
      />
      <path
        d="M614.024 153.694V153.694C625.265 153.694 634.572 162.429 635.285 173.648L637.66 211.065C639.7 243.192 666.352 268.206 698.544 268.206V268.206C730.736 268.206 757.388 243.192 759.428 211.065L761.689 175.462C762.466 163.223 772.619 153.694 784.882 153.694V153.694C797.146 153.694 807.299 163.223 808.076 175.462L810.329 210.95C812.373 243.142 839.079 268.206 871.336 268.206L872.923 268.206C905.18 268.206 931.886 243.142 933.929 210.95L936.183 175.462C936.96 163.223 947.113 153.694 959.377 153.694V153.694C971.64 153.694 981.793 163.223 982.57 175.462L984.824 210.95C986.868 243.142 1013.57 268.206 1045.83 268.206L1047.42 268.206C1079.67 268.206 1106.38 243.142 1108.42 210.95L1110.65 175.806C1111.44 163.374 1121.76 153.694 1134.22 153.694V153.694C1146.39 153.694 1156.56 162.948 1157.72 175.064L1161.14 210.95C1164.23 243.414 1191.49 268.206 1224.1 268.206L1230.21 268.206"
        stroke="black"
        strokeWidth="14.5412"
      />
      <path
        d="M614.024 139.153V139.153C633.097 139.153 648.559 154.615 648.559 173.688L648.559 203.679C648.559 231.286 670.938 253.665 698.544 253.665V253.665C726.15 253.665 748.529 231.286 748.529 203.679L748.529 175.506C748.529 155.429 764.805 139.153 784.882 139.153V139.153C804.96 139.153 821.235 155.429 821.235 175.506L821.235 202.771C821.235 230.879 844.021 253.665 872.129 253.665V253.665C900.238 253.665 923.024 230.879 923.024 202.771L923.024 175.506C923.024 155.429 939.299 139.153 959.377 139.153V139.153C979.454 139.153 995.729 155.429 995.729 175.506L995.729 202.771C995.729 230.879 1018.52 253.665 1046.62 253.665V253.665C1074.73 253.665 1097.52 230.879 1097.52 202.771L1097.52 175.506C1097.52 155.429 1113.79 139.153 1133.87 139.153V139.153C1153.95 139.153 1170.22 155.429 1170.22 175.506L1170.22 197.318C1170.22 228.437 1195.45 253.665 1226.57 253.665V253.665"
        stroke="#4A90E2"
        strokeWidth="14.5412"
      />
      <path
        d="M614.024 124.612V124.612C641.128 124.612 663.1 146.584 663.1 173.688L663.1 203.679C663.1 223.255 678.969 239.124 698.544 239.124V239.124C718.119 239.124 733.988 223.255 733.988 203.679L733.988 175.506C733.988 147.398 756.774 124.612 784.882 124.612V124.612C812.99 124.612 835.777 147.398 835.777 175.506L835.777 202.771C835.777 222.848 852.052 239.124 872.129 239.124V239.124C892.207 239.124 908.482 222.848 908.482 202.771L908.482 175.506C908.482 147.398 931.269 124.612 959.377 124.612V124.612C987.485 124.612 1010.27 147.398 1010.27 175.506L1010.27 202.771C1010.27 222.848 1026.55 239.124 1046.62 239.124V239.124C1066.7 239.124 1082.98 222.85 1082.98 202.773C1082.98 194.368 1082.98 185.068 1082.98 175.507C1082.98 147.399 1105.76 124.612 1133.87 124.612V124.612C1161.98 124.612 1184.76 147.398 1184.76 175.506L1184.76 197.318C1184.76 220.406 1203.48 239.124 1226.57 239.124V239.124"
        stroke="#FF007A"
        strokeWidth="14.5412"
      />
      <path
        d="M612.206 122.794V122.794C643.887 122.794 670.116 147.411 672.123 179.028L674.499 216.445C675.244 228.174 684.974 237.306 696.727 237.306V237.306C708.479 237.306 718.209 228.174 718.954 216.445L721.265 180.05C723.309 147.858 750.014 122.794 782.271 122.794L783.858 122.794C816.115 122.794 842.821 147.858 844.865 180.05L847.118 215.538C847.895 227.777 858.048 237.306 870.312 237.306V237.306C882.575 237.306 892.729 227.777 893.506 215.538L895.759 180.05C897.803 147.858 924.509 122.794 956.765 122.794L958.352 122.794C990.609 122.794 1017.31 147.858 1019.36 180.05L1021.61 215.538C1022.39 227.777 1032.54 237.306 1044.81 237.306V237.306C1057.07 237.306 1067.22 227.777 1068 215.538L1070.25 180.05C1072.3 147.858 1099 122.794 1131.26 122.794L1132.85 122.794C1165.1 122.794 1191.81 147.858 1193.85 180.05L1195.76 210.096C1196.73 225.395 1209.42 237.306 1224.75 237.306V237.306"
        stroke="#7F30FF"
        strokeWidth="14.5412"
      />
    </svg>
  );
};
